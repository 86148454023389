import axios from 'axios'
import Vue from 'vue'

const simmiConnect = axios.create({
  baseURL: process.env.VUE_APP_SIMMI_CONNECT_ENGINE,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})
simmiConnect.interceptors.request.use(async axiosConfig => {
  const user = await Vue.prototype.$auth.isAuthenticated
  if (!user) {
    return axiosConfig
  }
  const token = await Vue.prototype.$auth.getTokenSilently()
  if (
    axiosConfig.headers != null
      && axiosConfig.headers['X-Requested-With'] == null
  ) {
    // eslint-disable-next-line no-param-reassign
    axiosConfig.headers = {
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${token}`,
      ...axiosConfig.headers,
    }
  }
  // eslint-disable-next-line consistent-return
  return axiosConfig
})

// simmiConnect.interceptors.response.use(response => response,
//   async error => {
//     if (error.response.status === 403) {
//       await Vue.swal('You are not authorized for this!')
//       window.location.href = ('/')
//     } else if (error.response.status === 401) {
//       await Vue.swal('Subscription expired,You are not authorized for this!')
//       await Vue.prototype.$auth.logout({ returnTo: `${window.location.origin}/error-403` })
//       await Vue.prototype.$store.commit('user/CLEAR_USER_STORE')
//     }
//     return Promise.reject(error)
//   })
export default simmiConnect
