import { authGuard } from '@/auth/auth0/authGuard'

export default [
  {
    path: '/nest-stream',
    name: 'nest-stream',
    component: () => import('@/views/nestStream/Neststream.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Nest Stream',
    },
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/Callback.vue'),
    beforeEnter: authGuard,
    meta: {
      layout: 'full',
      pageTitle: 'Callback',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Logout',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      publicOnly: true,
    },
  },
  {
    path: '/launcher',
    name: 'Launcher',
    component: () => import('@/views/Loading.vue'),
    beforeEnter: authGuard,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-403',
    name: 'error-403',
    component: () => import('@/views/error/Error403.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
