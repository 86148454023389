import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import dashboard from '@/router/pages/dashboard'
import others from '@/router/pages/others'
import { getInstance } from '@/auth/auth0'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...dashboard,
    ...others,
  ],
})
const setSelectedGroupId = async groupId => {
  await store.commit('persistedStore/SET_GROUP_ID_FROM_QUERY', groupId)
}
const fetchUserDataIfRequired = async () => {
  const auth0Instance = Vue.prototype.$auth
  try {
    await store.dispatch('user/getUserIfNotExist', false)
  } catch {
    auth0Instance.logout({ returnTo: `${window.location.origin}/error-403` })
  }
}

const waitForAuth0ToBeInitialized = async () => {
  const appInitialized = store.getters['app/getAuth0Initialized']
  if (!appInitialized) {
    const instance = getInstance()
    await new Promise(resolve => {
      instance.$watch('loading', async loading => {
        if (loading === false) {
          await store.commit('app/SET_AUTH0_INITIALIZED')
          resolve('')
        }
      })
    })
  }
}
const checkForAuth0Authentication = async to => {
  if (to.query && to.query.logout) {
    Vue.prototype.$tabEvent.emit('logout-user')
    await Vue.prototype.$auth.logout({ localOnly: true })
    window.close()
  }
}
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
router.beforeEach(async (to, from, next) => {
  const { groupId } = to.query
  await waitForAuth0ToBeInitialized()
  await checkForAuth0Authentication(to)
  const auth0Instance = Vue.prototype.$auth

  if (groupId) {
    await setSelectedGroupId(Number(groupId))
  }
  if (to.meta.requireAuth) {
    if (!auth0Instance.isAuthenticated) {
      next('/login')
    } else {
      await fetchUserDataIfRequired()
      next()
    }
  } else if (auth0Instance.isAuthenticated && to.meta.publicOnly) {
    next({
      path: '/',
    })
  }
  next() // make sure to always call next()!
})

export default router
