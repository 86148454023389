import Vue from 'vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { getLoggedInUser } from '@/api/admin/simmiConnectEngine/user'

export default {
  namespaced: true,
  state: {
    userIP: null,
    currentUser: {
      date_of_birth: '0000-00-00',
    },
    userDataFetched: false,
  },
  mutations: {
    SET_USER(state, payload) {
      state.currentUser = payload
    },
    SET_USER_DATA_FETCHED(state, payload) {
      state.userDataFetched = payload
    },
    CLEAR_USER_STORE(state) {
      state.currentUser = {
        date_of_birth: '0000-00-00',
      }
      state.userGroups = []
    },
  },
  actions: {
    async getUserIfNotExist({ commit, state }, forceFetch = false) {
      if (forceFetch || !state.currentUser.id) {
        try {
          commit('SET_USER_DATA_FETCHED', false)
          const { data: { data } } = await getLoggedInUser()
          commit('SET_USER', data)
        } catch (e) {
          commit('CLEAR_USER_STORE')
          throw new Error('User not have access to this portal, logging out...')
        } finally {
          commit('SET_USER_DATA_FETCHED', true)
        }
      }
    },
    async logoutUserFromOtherApp() {
      try {
        await window.open(`${process.env.VUE_APP_SIMI_CONNECT_BASE_URL}?logout=true`, 'IOTConnect')
        await window.open(`${process.env.VUE_APP_SIMI_LAUNCHER_BASE_URL}?logout=true`, 'IOTLauncher')
      } catch (e) {
        // console.error(e)
      }
    },
    async logoutUserWithRedirect() {
      await Vue.prototype.$auth.logout({ returnTo: `${window.location.origin}/logout` })
    },
    async logoutUserLocalOnly({ commit }) {
      await Vue.prototype.$auth.logout({ localOnly: true })
      commit('CLEAR_USER_STORE')
    },
  },
  getters: {
    getUser: state => state.currentUser,
    getAllUserGroups: state => state.userGroups,
    isUserDataFetched: state => state.userDataFetched,
    isUserAdmin(state) {
      let isAdmin = false
      if (state.currentUser && state.currentUser.user_has_role && state.currentUser.user_has_role.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const userHasRoleElement of state.currentUser.user_has_role) {
          if (userHasRoleElement.role && userHasRoleElement.role.role === AccountTypes.ADMIN) {
            isAdmin = true
            break
          }
        }
      }
      return isAdmin
    },
  },
}
