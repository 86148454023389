import Vue from 'vue'
import VueCarousel from 'vue-carousel'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2'
import VueCookies from 'vue-cookies'
import moment from 'moment'
import vueTabevents from 'vue-tabevents'
import simmiConnect from './auth/simmiConnect'
import simmiIOT from './auth/simmiIOT'
import router from './router'
import store from './store'
import App from './App.vue'
import 'vuesax/dist/vuesax.css'
// Global Components
import './global-components'
// Import the Auth0 configuration
// import { domain, clientId } from '../auth_config.json'
import { domain, clientId } from '../auth_config.json'
// require videojs style
// eslint-disable-next-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.min.css'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
// import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-it-bigger/dist/vue-it-bigger.min.css'
import { Auth0Plugin } from '@/auth/auth0'

Vue.use(moment)
Vue.use(VueCookies)
Vue.use(VueCarousel)
Vue.use(vueTabevents)
// API plugins
Vue.prototype.$simmiConnect = simmiConnect
Vue.prototype.$simmiIOT = simmiIOT
Vue.prototype.$store = store
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    )
  },
})
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Composition API
Vue.use(VueCompositionAPI)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.use(VueSweetalert2)

Vue.config.productionTip = false
const app = null

// auth.initApp(Vue, app, router, store, App)
new Vue({
  Vue,
  app,
  router,
  store,
  App,
  render: h => h(App),
}).$mount('#app')
